import { isEqual } from "lodash-es";
import { useRef, useState, useEffect, useCallback } from "react";
function useAnimation({
  baseStyle,
  steps
}) {
  const prevSteps = useRef(steps);
  const [style, setStyle] = useState({
    ...baseStyle
  });
  useEffect(() => {
    if (!cancelAnimation.current) {
      setStyle({ ...baseStyle });
    }
  }, [baseStyle]);
  const animate = useCallback(() => {
    let cancelled = false;
    const innerAnimation = async () => {
      for (const step of steps) {
        if (cancelled) {
          return;
        }
        setStyle({
          ...baseStyle,
          ...step.style
        });
        await new Promise((r) => {
          setTimeout(() => {
            r();
          }, step.delay);
        });
      }
      if (!cancelled) {
        cancelAnimation.current = null;
      }
    };
    if (!isEqual(prevSteps.current, steps)) {
      prevSteps.current = steps;
      void innerAnimation().catch();
      return () => {
        cancelled = true;
      };
    }
    return null;
  }, [steps, baseStyle]);
  const cancelAnimation = useRef(null);
  useEffect(() => {
    const cancel = cancelAnimation.current;
    if (cancel) {
      cancel();
    }
    cancelAnimation.current = animate();
  }, [animate]);
  return style;
}
export {
  useAnimation as default
};
